@charset "UTF-8";
@font-face {
  font-family: "Campton";
  src: url("../font/Campton-Light.eot?#iefix") format("embedded-opentype"), url("../font/Campton-Light.woff2") format("woff2"), url("../font/Campton-Light.woff") format("woff");
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
}

a, .link {
  outline: none;
  color: inherit;
  background-color: transparent;
}

a:hover, .link:hover {
  color: inherit;
  text-decoration: none;
}

a:active, .link:active {
  color: inherit;
  text-decoration: none;
}

.link--ib {
  display: inline-block;
  line-height: 1.1;
}

.link--bordered {
  text-decoration: none !important;
  border-bottom: 1px solid;
  border-bottom-color: inherit;
}

.link--dotted {
  text-decoration: none !important;
  border-bottom: 1px dotted;
  border-bottom-color: inherit;
}

sub, sup {
  font-size: 65%;
}

img {
  max-width: 100%;
}

img.fullwidth {
  display: block;
  width: 100%;
  height: auto;
}

strong {
  font-weight: 700;
}

h1, .h1 {
  font-size: 45px;
  font-weight: 300;
  line-height: 1.44444;
  letter-spacing: .2em;
  text-transform: uppercase;
  margin: 40px 0 0;
}

h1:first-child, .h1:first-child {
  margin-top: 0;
}

h2, .h2 {
  font-size: 25px;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 40px 0 0;
}

h2:first-child, .h2:first-child {
  margin-top: 0;
}

h2--smaller, .h2--smaller {
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: .1em;
}

h3, .h3 {
  font-size: 15px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #8A202F;
  margin: 40px 0 0;
}

h3:first-child, .h3:first-child {
  margin-top: 0;
}

h3 + hr, .h3 + hr {
  width: 30px;
  border-color: #8A202F;
  margin-top: 3px;
  margin-bottom: 0;
}

h4, .h4 {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.25;
  margin: 40px 0 0;
}

h4:first-child, .h4:first-child {
  margin-top: 0;
}

p, .p {
  margin: 30px 0 0;
  -webkit-text-size-adjust: 100%;
}

p:first-child, .p:first-child {
  margin-top: 0;
}

cite {
  font-family: Georgia;
  font-size: 15px;
  font-style: italic;
  line-height: 2;
}

ol, ul {
  margin: 20px 0 0;
  padding: 0;
}

ol:first-child, ul:first-child {
  margin-top: 0;
}

ol li, ul li {
  margin: 12px 0 0;
  padding: 0 0 0 30px;
}

ol li:first-child, ul li:first-child {
  margin-top: 0;
}

li ul, li ol {
  margin-top: 12px;
}

li li {
  margin-top: 6px;
}

ul {
  list-style: none;
}

ul li {
  position: relative;
  padding-left: 21px;
  text-indent: -21px;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -o-column-break-inside: avoid;
  -ms-column-break-inside: avoid;
  column-break-inside: avoid;
  page-break-inside: avoid;
       break-inside: avoid-column;
}

ul li * {
  text-indent: 0;
}

ul li:before {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 15px;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #8A202F;
  text-indent: 0;
  content: "";
}

ol {
  counter-reset: ol_counter;
}

ol li {
  text-indent: -30px;
  list-style: none;
  padding: 0 0 0 45px;
}

ol li:before {
  display: inline-block;
  text-indent: 0;
  height: 20px;
  width: 20px;
  line-height: 22px;
  margin-right: 10px;
  text-align: center;
  background-color: #c2c2c2;
  border-radius: 50%;
  color: #fff;
  counter-increment: ol_counter;
  content: counter(ol_counter);
}

nav ul, .nav ul {
  margin: 0;
  padding: 0;
}

nav li, .nav li {
  margin: 0;
  padding: 0;
  text-indent: 0;
}

nav li:before, .nav li:before {
  display: none;
}

table th, table td {
  padding: 3px 10px;
}

/*

Icons

*/
.icon {
  position: relative;
  display: inline-block;
  fill: currentColor;
  width: 1em;
  height: 1em;
  letter-spacing: 0;
  line-height: 1;
}

svg.icon {
  pointer-events: none;
}

.icon--logo {
  width: 4.70588em;
}

.icon--logo-vertical {
  width: 1.26309em;
}

.icon--bulb {
  width: 0.66667em;
}

.icon--tick {
  width: 0.95238em;
}

/*

Expansible content

 */
.expand-it-container {
  position: relative;
  overflow: hidden;
  max-height: 0;
  pointer-events: none;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.expand-it-container.overflow-visible {
  overflow: visible;
  max-height: 99999px !important;
}

.expand-it-container.active {
  max-height: 99999px;
  pointer-events: auto;
}

/*

Column Layout

*/
.cols {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.cols--auto {
  table-layout: auto;
}

.cols--fullheight {
  height: 100%;
}

.cols__inner {
  display: table-row;
}

.col {
  display: table-cell;
  vertical-align: top;
  padding: 0 15px;
}

.col:first-child {
  padding-left: 0;
}

.col:last-child {
  padding-right: 0;
}

.cols-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}

.cols-wrapper > .cols > .col:first-child {
  padding-left: 15px;
}

.cols-wrapper > .cols > .col:last-child {
  padding-right: 15px;
}

.cols--reverse {
  direction: rtl;
}

.cols--reverse > .col {
  direction: ltr;
}

.col--fit {
  width: 1px;
}

.col--1-4 {
  width: 25%;
}

.col--3-4 {
  width: 75%;
}

.col--1-3 {
  width: 33.33%;
}

.col--2-3 {
  width: 66.66%;
}

.col--1-2 {
  width: 50%;
}

.cols--floats {
  display: block;
  width: auto;
  margin-left: -10px;
  margin-right: -10px;
}

.cols--floats:after {
  content: "";
  display: table;
  clear: both;
}

.cols--floats > .cols__inner {
  display: block;
  float: left;
  width: 100%;
  margin-top: -10px;
  margin-bottom: -10px;
}

.cols--floats > .cols__inner > .col {
  float: left;
  display: block;
  padding: 10px;
}

.cols--floats > .cols__inner > .col--right {
  float: right;
}

.cols--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.cols--flex > .col {
  display: block;
}

/*

Form's layout

*/
.form {
  position: relative;
  margin-top: 30px;
}

.form:after {
  content: "";
  display: table;
  clear: both;
}

.form:first-child {
  margin-top: 0;
}

.form__items {
  position: relative;
  margin-top: 20px;
}

.form__items:after {
  content: "";
  display: table;
  clear: both;
}

.form__items:first-child {
  margin-top: 0;
}

.form__item {
  position: relative;
  margin-top: 20px;
}

.form__item:after {
  content: "";
  display: table;
  clear: both;
}

.form__item:first-child {
  margin-top: 0;
}

.form__item-label {
  display: block;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  margin-top: 10px;
}

.form__item-label:first-child {
  margin-top: 0;
}

.form__item-field {
  position: relative;
  margin-top: 10px;
}

.form__item-field:first-child {
  margin-top: 0;
}

.form__item-field .required {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 10px;
  font-size: 16px;
  color: #E42626;
  pointer-events: none;
}

.form__actions {
  position: relative;
  margin-top: 30px;
}

.form__actions:first-child {
  margin-top: 0;
}

.form__tip {
  margin-top: 15px;
}

.form__tip:first-child {
  margin-top: 0;
}

.form__error {
  font-size: 15px;
  line-height: 1.6;
  padding: 0;
  color: #484848;
  -webkit-animation: 0.5s ease-out;
          animation: 0.5s ease-out;
  -webkit-animation-iteration-count: 2;
          animation-iteration-count: 2;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  -webkit-animation-name: pulsateError;
          animation-name: pulsateError;
}

@media (max-width: 479px) {
  .form__error {
    font-size: 12px;
  }
}

.form__item-field--error-absolute .form__error {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
}

.form__item-field--error-white .form__error {
  color: #fff;
}

.form__error.active {
  -webkit-animation-name: pulsateError;
          animation-name: pulsateError;
}

.form__message {
  margin-top: 30px;
  background-color: #fff;
  color: #646569;
  padding: 15px 20px;
  text-align: center;
  display: none;
}

.form__message:first-child {
  margin-top: 0;
}

.form__message.active {
  display: block;
}

@-webkit-keyframes pulsateError {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1.0;
  }
}

@keyframes pulsateError {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1.0;
  }
}

/*

Textfields

*/
textarea,
input,
button {
  -webkit-font-smoothing: antialiased;
  outline: none;
  line-height: 1.25;
  font-family: "Campton", Tahoma, Arial, Helvetica, sans-serif;
}

.textfield {
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Campton", Tahoma, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.285714285714286;
  background: #fff;
  color: #646569;
  padding: 10px 15px;
  height: 38px;
  width: 100%;
  border: none;
  -webkit-box-shadow: inset 0 0 0 1px #dfdfdf;
          box-shadow: inset 0 0 0 1px #dfdfdf;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  resize: none;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  -webkit-transition: color 0.2s, background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
  -o-transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.textfield:focus {
  outline: none;
}

.textfield:invalid {
  background-color: #fff;
}

.textfield::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.textfield::-webkit-input-placeholder {
  opacity: 1;
  color: #767676;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.textfield::-moz-placeholder {
  opacity: 1;
  color: #767676;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.textfield:-moz-placeholder {
  opacity: 1;
  color: #767676;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.textfield:-ms-input-placeholder {
  opacity: 1;
  color: #767676;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.textfield.active {
  -webkit-box-shadow: inset 0 0 0 1px #8A202F;
          box-shadow: inset 0 0 0 1px #8A202F;
}

.textfield.active:focus {
  -webkit-box-shadow: inset 0 0 0 2px #2977C2;
          box-shadow: inset 0 0 0 2px #2977C2;
}

.textfield.error {
  -webkit-box-shadow: inset 0 0 0 1px #484848;
          box-shadow: inset 0 0 0 1px #484848;
}

.textfield[disabled], .textfield.disabled, .textfield[readonly] {
  opacity: 1;
  background: #eee;
  border-color: transparent;
  cursor: default;
  cursor: not-allowed;
  color: #aaa;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.textfield[disabled]::-webkit-input-placeholder, .textfield.disabled::-webkit-input-placeholder, .textfield[readonly]::-webkit-input-placeholder {
  color: #aaa;
}

.textfield[disabled]::-moz-placeholder, .textfield.disabled::-moz-placeholder, .textfield[readonly]::-moz-placeholder {
  color: #aaa;
}

.textfield[disabled]:-moz-placeholder, .textfield.disabled:-moz-placeholder, .textfield[readonly]:-moz-placeholder {
  color: #aaa;
}

.textfield[disabled]:-ms-input-placeholder, .textfield.disabled:-ms-input-placeholder, .textfield[readonly]:-ms-input-placeholder {
  color: #aaa;
}

textarea.textfield {
  white-space: normal;
  overflow: auto;
  height: auto;
}

html {
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  background: #fff;
  height: 100%;
}

body {
  position: relative;
  font-family: "Campton", Tahoma, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #646569;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  height: 100%;
  background: #fff;
  min-width: 320px;
  overflow-x: hidden;
}

@media (max-width: 319px) {
  body {
    overflow-x: auto;
  }
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.no-animation-only-this {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.no-animation {
  -webkit-animation-delay: 0s !important;
          animation-delay: 0s !important;
  -webkit-animation-duration: 0s !important;
          animation-duration: 0s !important;
}

.no-animation *:before, .no-animation *:after {
  -webkit-animation-delay: 0s !important;
          animation-delay: 0s !important;
  -webkit-animation-duration: 0s !important;
          animation-duration: 0s !important;
}

.no-transition-only-this {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.no-transition {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.no-transition *:not(.swiper-wrapper), .no-transition *:before, .no-transition *:after {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

::-moz-selection {
  background: #eee;
  color: #000;
  text-shadow: none;
}

::selection {
  background: #eee;
  color: #000;
  text-shadow: none;
}

/*
*
* Skeleton elements
*
*/
.container {
  width: 100%;
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

.container:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 374px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.container--large {
  max-width: 1370px;
}

.container--xlarge {
  max-width: 1500px;
}

.wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 0;
  float: left;
  width: 100%;
  min-height: 100%;
  background: #fff;
  overflow: hidden;
}

/*

Responsive Helpers

 */
.respon-meter {
  position: absolute;
  z-index: 9999;
}

@media (max-width: 1350px) {
  .respon-meter {
    z-index: 1350;
  }
}

@media (max-width: 1259px) {
  .respon-meter {
    z-index: 1259;
  }
}

@media (max-width: 1023px) {
  .respon-meter {
    z-index: 1023;
  }
}

@media (max-width: 767px) {
  .respon-meter {
    z-index: 640;
  }
}

@media (max-width: 479px) {
  .respon-meter {
    z-index: 479;
  }
}

@media (max-width: 413px) {
  .respon-meter {
    z-index: 414;
  }
}

@media (max-width: 374px) {
  .respon-meter {
    z-index: 374;
  }
}

@media (max-width: 359px) {
  .respon-meter {
    z-index: 359;
  }
}

.only-for-notouch,
.only-for-touch,
.only-for-notouch-inline,
.only-for-touch-inline,
.only-for-notouch-inline-block,
.only-for-touch-inline-block {
  display: none !important;
}

.no-touchevents .only-for-notouch {
  display: block !important;
}

.no-touchevents .only-for-notouch-inline {
  display: inline !important;
}

.no-touchevents .only-for-notouch-inline-block {
  display: inline-block !important;
}

.touchevents .only-for-touch {
  display: block !important;
}

.touchevents .only-for-touch-inline {
  display: inline !important;
}

.touchevents .only-for-touch-inline-block {
  display: inline-block !important;
}

.tablet-show,
.tablet-show-inline-block,
.tablet-show-inline,
.tablet-show-table-row,
.tablet-show-table-cell {
  display: none !important;
}

.tablet-small-show,
.tablet-small-show-inline-block,
.tablet-small-show-inline,
.tablet-small-show-table-row,
.tablet-small-show-table-cell {
  display: none !important;
}

.mobile-show,
.mobile-show-inline-block,
.mobile-show-inline,
.mobile-show-table-cell {
  display: none !important;
}

.mobile-small-show,
.mobile-small-show-inline-block,
.mobile-small-show-inline,
.mobile-small-show-table-cell {
  display: none !important;
}

.mobile-xsmall-show,
.mobile-xsmall-show-inline-block,
.mobile-xsmall-show-inline,
.mobile-xsmall-show-table-cell {
  display: none !important;
}

.mobile-xxsmall-show,
.mobile-xxsmall-show-inline-block,
.mobile-xxsmall-show-inline,
.mobile-xxsmall-show-table-cell {
  display: none !important;
}

.mobile-xxxsmall-show,
.mobile-xxxsmall-show-inline-block,
.mobile-xxxsmall-show-inline,
.mobile-xxxsmall-show-table-cell {
  display: none !important;
}

.initial-hide {
  display: none !important;
}

.initial-show {
  display: block !important;
}

.initial-show-inline-block {
  display: inline-block !important;
}

.initial-show-inline {
  display: inline !important;
}

.initial-show-table-row {
  display: table-row !important;
}

.initial-show-table-cell {
  display: table-cell !important;
}

@media (max-width: 1259px) {
  .tablet-hide {
    display: none !important;
  }
  .tablet-show {
    display: block !important;
  }
  .tablet-show-inline-block {
    display: inline-block !important;
  }
  .tablet-show-inline {
    display: inline !important;
  }
  .tablet-show-table-row {
    display: table-row !important;
  }
  .tablet-show-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 1023px) {
  .tablet-small-hide {
    display: none !important;
  }
  .tablet-small-show {
    display: block !important;
  }
  .tablet-small-show-inline-block {
    display: inline-block !important;
  }
  .tablet-show-inline {
    display: inline !important;
  }
  .tablet-small-show-table-row {
    display: table-row !important;
  }
  .tablet-small-show-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .mobile-hide {
    display: none !important;
  }
  .mobile-show {
    display: block !important;
  }
  .mobile-show-inline-block {
    display: inline-block !important;
  }
  .mobile-show-inline {
    display: inline !important;
  }
  .mobile-show-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 479px) {
  .mobile-small-hide {
    display: none !important;
  }
  .mobile-small-show {
    display: block !important;
  }
  .mobile-small-show-inline-block {
    display: inline-block !important;
  }
  .mobile-small-show-inline {
    display: inline !important;
  }
  .mobile-small-show-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 413px) {
  .mobile-xsmall-hide {
    display: none !important;
  }
  .mobile-xsmall-show {
    display: block !important;
  }
  .mobile-xsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xsmall-show-inline {
    display: inline !important;
  }
  .mobile-xsmall-show-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 374px) {
  .mobile-xxsmall-hide {
    display: none !important;
  }
  .mobile-xxsmall-show {
    display: block !important;
  }
  .mobile-xxsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xxsmall-show-inline {
    display: inline !important;
  }
  .mobile-xxsmall-show-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 359px) {
  .mobile-xxxsmall-hide {
    display: none !important;
  }
  .mobile-xxxsmall-show {
    display: block !important;
  }
  .mobile-xxxsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xxxsmall-show-inline {
    display: inline !important;
  }
  .mobile-xxxsmall-show-table-cell {
    display: table-cell !important;
  }
}

.header {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 15;
  will-change: transform;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  min-height: 180px;
  pointer-events: none;
}

@media (max-width: 767px) {
  .header {
    min-height: 90px;
  }
}

.menu-overlay-active .header {
  background-color: #fff;
}

.header.nav-up {
  -webkit-transform: translate3d(0, -180px, 0);
          transform: translate3d(0, -180px, 0);
}

.header.scrolled {
  position: fixed;
}

.header-absolute .header {
  position: absolute !important;
}

.header-absolute .header .header__inner {
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.menu-overlay-active .header-absolute .header .header__inner {
  background-color: #fff;
  -webkit-box-shadow: inset 0 -1px 0 0 #F2F4F6;
          box-shadow: inset 0 -1px 0 0 #F2F4F6;
}

.header__inner {
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: inset 0 -1px 0 0 #F2F4F6;
          box-shadow: inset 0 -1px 0 0 #F2F4F6;
  pointer-events: auto;
}

.header__inner a {
  color: inherit;
}

.header__grid {
  display: table;
  width: 100%;
}

.header__grid-wrapper {
  margin: 0 -20px;
}

.header__cell {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding: 0 20px;
  height: 180px;
  text-align: left;
  -webkit-transition: height 0.2s ease-in-out, padding-top 0.2s ease-in-out;
  -o-transition: height 0.2s ease-in-out, padding-top 0.2s ease-in-out;
  transition: height 0.2s ease-in-out, padding-top 0.2s ease-in-out;
}

@media (max-width: 767px) {
  .header__cell {
    height: 90px;
  }
}

body:not(.header-absolute) .scrolled .header__cell, body:not(.header-absolute)
.scrolled.nav-up .header__cell, body:not(.header-absolute)
.scrolled.nav-down .header__cell {
  height: 100px;
}

@media (max-width: 767px) {
  body:not(.header-absolute) .scrolled .header__cell, body:not(.header-absolute)
  .scrolled.nav-up .header__cell, body:not(.header-absolute)
  .scrolled.nav-down .header__cell {
    height: 90px;
  }
}

.header__cell--fit {
  width: 1%;
  white-space: nowrap;
}

.header__cell--logo {
  width: 1%;
  text-align: left;
}

.header__cell--cbutton {
  width: 1%;
  text-align: right;
  font-size: 0;
}

@media (max-width: 767px) {
  .header-before {
    height: 45px;
  }
}

.header-replace {
  height: 180px;
  display: none;
}

.scrolled + .header-replace {
  display: block;
}

.header-absolute .header + .header-replace {
  display: none;
}

@media (max-width: 767px) {
  .header-replace {
    height: 90px;
  }
}

.logo {
  display: inline-block;
  vertical-align: top;
  font-size: 39px;
  line-height: 1;
  white-space: normal;
  color: inherit !important;
  text-decoration: none !important;
}

.logo .icon {
  vertical-align: top;
}

.logo--invisible {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1), visibility 0s 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1), visibility 0s 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1), visibility 0s 0.7s cubic-bezier(0.23, 1, 0.32, 1);
}

.menu-overlay-active .logo--invisible {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.logo--home {
  font-size: 330px;
}

@media (max-height: 900px) {
  .logo--home {
    font-size: 280px;
  }
}

@media (max-height: 800px) {
  .logo--home {
    font-size: 250px;
  }
}

@media (max-height: 600px) {
  .logo--home {
    font-size: 180px;
  }
}

@media (max-width: 767px) {
  .logo--home {
    font-size: 210px;
  }
}

@media (max-width: 767px) and (max-height: 800px) {
  .logo--home {
    font-size: 180px;
  }
}

@media (max-width: 359px) {
  .logo--home {
    font-size: 180px;
  }
}

.cbutton {
  display: inline-block;
  cursor: pointer;
  z-index: 2;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.cbutton__inner {
  position: relative;
  line-height: 1;
  z-index: 1;
}

.cbutton__item {
  display: block;
  height: 2px;
  width: 30px;
  background-color: currentColor;
  margin: 7px 0 0 0;
  -webkit-transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
}

.menu-overlay-active .cbutton__item {
  opacity: 0;
  -webkit-transform: translateY(-12px) rotate(-45deg);
      -ms-transform: translateY(-12px) rotate(-45deg);
          transform: translateY(-12px) rotate(-45deg);
}

.cbutton__item:first-child {
  margin-top: 0;
}

.menu-overlay-active .cbutton__item:first-child {
  opacity: 1;
  -webkit-transform: translateY(8px) rotate(135deg);
      -ms-transform: translateY(8px) rotate(135deg);
          transform: translateY(8px) rotate(135deg);
}

.menu-overlay-active .cbutton__item:first-child + * {
  opacity: 1;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.hmenu__inner {
  margin-left: -20px;
}

@media (max-width: 767px) {
  .hmenu__inner {
    margin-left: 0;
  }
}

.hmenu__item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  font-size: 1rem;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 767px) {
  .hmenu__item {
    display: block;
    margin-left: 0;
    margin-top: 30px;
  }
  .hmenu__item:first-child {
    margin-top: 0;
  }
}

.hmenu__item:hover .hsmenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.hmenu__item.focused .hsmenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.hmenu__item-inner {
  position: relative;
  display: block;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 2.30769;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  color: inherit;
}

@media (max-width: 767px) {
  .hmenu__item-inner {
    font-size: 25px;
    letter-spacing: 0;
    line-height: 1.6;
    text-transform: none;
    text-decoration: none !important;
  }
  .hmenu__item-inner.expand-it {
    position: relative;
  }
  .hmenu__item-inner.expand-it.active:before {
    -webkit-transform: scaleY(0);
        -ms-transform: scaleY(0);
            transform: scaleY(0);
  }
  .hmenu__item-inner.expand-it:before {
    position: absolute;
    top: 16px;
    right: 14px;
    width: 1px;
    height: 15px;
    background-color: currentColor;
    content: '';
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .hmenu__item-inner.expand-it:after {
    float: right;
    margin: 23px 7px 0 15px;
    width: 15px;
    height: 1px;
    background-color: currentColor;
    content: '';
  }
}

.hmenu__item-inner:hover {
  text-decoration: underline;
}

.hmenu__item-inner:active {
  text-decoration: underline;
}

.hsmenu {
  position: absolute;
  top: 100%;
  right: 0;
  padding-top: 30px;
  min-width: 260px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate3d(0, 10px, 0);
          transform: translate3d(0, 10px, 0);
  -webkit-transition: all 0.4s, visibility 0s 0.4s;
  -o-transition: all 0.4s, visibility 0s 0.4s;
  transition: all 0.4s, visibility 0s 0.4s;
}

@media (min-width: 768px) {
  .hsmenu {
    overflow: visible;
    max-height: none;
    pointer-events: auto;
  }
}

@media (max-width: 767px) {
  .hsmenu {
    padding-top: 0;
    position: relative;
    top: auto;
    margin-left: -30px;
    margin-right: -30px;
  }
}

@media (max-width: 374px) {
  .hsmenu {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.hsmenu__inner {
  background-color: #fff;
  border: 1px solid #F2F4F6;
  text-align: left;
}

@media (max-width: 767px) {
  .hsmenu__inner {
    padding-top: 20px;
    border: none;
  }
}

.hsmenu__item {
  border-top: 1px solid #F2F4F6;
}

@media (max-width: 767px) {
  .hsmenu__item {
    border-top: none;
    border-bottom: 1px solid #F2F4F6;
  }
}

.hsmenu__item:first-child {
  border-top: none;
}

.hsmenu__item-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px 10px;
  font-size: 15px;
  line-height: 1.2;
  text-decoration: none !important;
}

@media (max-width: 767px) {
  .hsmenu__item-inner {
    padding: 19px 30px;
  }
}

@media (max-width: 374px) {
  .hsmenu__item-inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.hsmenu__item-inner:hover {
  background-color: #F2F4F6;
}

.hsmenu__item-inner:active {
  background-color: #F2F4F6;
}

.hsmenu__item-inner > * {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
}

.hsmenu__item-image {
  margin: 0 15px 0 0;
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
}

.hsmenu__item-icon {
  margin: 0 5px 0 auto;
  padding-left: 35px;
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
  font-size: 15px;
  line-height: 1;
}

.hsmenu__item-icon .icon {
  vertical-align: top;
}

.menu-overlay {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  height: calc(100vh - 180px);
  background-color: #fff;
  font-size: 14px;
  line-height: 1.2;
  z-index: -1;
  pointer-events: auto;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  padding: 120px 0;
  -webkit-transition: opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1), visibility 0s 0.7s cubic-bezier(0.23, 1, 0.32, 1), z-index 0s 0.7s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1), visibility 0s 0.7s cubic-bezier(0.23, 1, 0.32, 1), z-index 0s 0.7s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: transform 0.7s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1), visibility 0s 0.7s cubic-bezier(0.23, 1, 0.32, 1), z-index 0s 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.7s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1), visibility 0s 0.7s cubic-bezier(0.23, 1, 0.32, 1), z-index 0s 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.7s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1), visibility 0s 0.7s cubic-bezier(0.23, 1, 0.32, 1), z-index 0s 0.7s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
}

@media (max-height: 767px) {
  .menu-overlay {
    padding: 80px 0;
    height: calc(100vh - 135px);
  }
}

@media (max-width: 767px) {
  .menu-overlay {
    height: calc(100vh - 90px);
  }
}

@media (max-height: 700px) {
  .menu-overlay {
    padding: 50px 0;
  }
}

@media (max-height: 500px) {
  .menu-overlay {
    padding: 20px 0;
  }
}

.menu-overlay a {
  color: inherit;
}

.menu-overlay-active .menu-overlay {
  z-index: 15;
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.footer {
  position: relative;
  margin-top: auto;
  padding: 60px 0;
}

.footer .col--links {
  font-size: 13px;
  letter-spacing: 1px;
}

.footer a {
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer a:active {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .cols-wrapper--footer > .cols {
    display: block;
  }
  .cols-wrapper--footer > .cols > .col {
    display: block;
    margin-top: 15px;
  }
  .cols-wrapper--footer > .cols > .col:first-child {
    margin-top: 0;
  }
}

@media (max-width: 767px) and (max-width: 767px) {
  .cols-wrapper--footer > .cols > .col {
    text-align: center;
  }
}

/*
Заготовка по умолчанию для разделов страницы
*/
.section {
  position: relative;
  z-index: 1;
  padding: 70px 0;
}

.section--bring-to-front {
  z-index: 2;
}

.section--bring-to-back {
  z-index: 0;
}

.section__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: center center no-repeat;
  background-size: cover;
  z-index: -1;
  overflow: hidden;
}

.section__bg-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: center center no-repeat;
  background-size: cover;
}

.section__bg-inner--bg-fixed {
  background-attachment: fixed;
}

.section__bg.img-to-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.section__bg.img-to-bg-lazy-loaded {
  opacity: 1;
}

.section__bg img {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
  max-width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: -100;
}

.section__bg video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  visibility: hidden;
}

.section__bg video.auto-resized {
  visibility: visible;
}

.section--red {
  background-color: #8A202F;
  color: #fff;
}

.section--red.scroll-activate {
  background-color: #fff;
  -webkit-transition-duration: 0.7s;
       -o-transition-duration: 0.7s;
          transition-duration: 0.7s;
}

.section--red.scroll-active {
  background-color: #8A202F;
}

.lquote {
  font-size: 25px;
  line-height: 1.6;
  letter-spacing: 1px;
  border-top: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
  padding: 30px 0;
  margin: 0;
}

.social {
  margin-top: 30px;
}

.social:first-child {
  margin-top: 0;
}

.social ul {
  margin-left: -15px;
  letter-spacing: 0;
  font-size: 0;
}

.social li {
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
  margin-left: 15px;
}

.social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 45px;
  width: 45px;
  font-size: 15px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1.5px solid currentColor;
  border-radius: 50%;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.social a:hover {
  color: #8A202F;
  background-color: #fff;
  border-color: #fff;
}

.section--hero {
  background-image: url("../img/bg-logo-corner.png");
  background-position: right bottom;
  background-size: 356px auto;
  background-repeat: no-repeat;
  padding: 0;
}

.cols-wrapper--hero {
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 1500px) {
  .cols-wrapper--hero {
    margin-left: -30px;
    margin-right: -30px;
  }
}

@media (max-width: 374px) {
  .cols-wrapper--hero {
    margin-left: -20px;
    margin-right: -20px;
  }
}

@media (max-width: 767px) {
  .cols-wrapper--hero > .cols {
    display: block;
  }
}

.cols-wrapper--hero > .cols > .col {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 767px) {
  .cols-wrapper--hero > .cols > .col {
    display: block;
  }
}

.cols-wrapper--hero > .cols > .col:first-child {
  padding-left: 0;
}

.cols-wrapper--hero > .cols > .col:last-child {
  padding-right: 0;
}

@media (max-width: 1023px) {
  .cols-wrapper--hero > .cols > .col--image {
    width: 33.33%;
    padding-top: 90px;
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .cols-wrapper--hero > .cols > .col--image {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.cols-wrapper--hero > .cols > .col--image.scroll-activate {
  opacity: 0;
  -webkit-transform: translateX(-3em);
      -ms-transform: translateX(-3em);
          transform: translateX(-3em);
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

.cols-wrapper--hero > .cols > .col--image.scroll-active {
  opacity: 1;
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
}

.cols-wrapper--hero > .cols > .col--text {
  padding: 60px;
}

@media (max-width: 767px) {
  .cols-wrapper--hero > .cols > .col--text {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.cols-wrapper--hero > .cols > .col--text:first-child {
  padding-left: 50px;
}

@media (max-width: 767px) {
  .cols-wrapper--hero > .cols > .col--text:first-child {
    padding-left: 30px;
  }
}

.cols-wrapper--hero > .cols > .col--text:last-child {
  padding-right: 50px;
}

@media (max-width: 767px) {
  .cols-wrapper--hero > .cols > .col--text:last-child {
    padding-right: 30px;
  }
}

.cols-wrapper--hero > .cols > .col--text.scroll-activate {
  opacity: 0;
  -webkit-transform: translateX(3em);
      -ms-transform: translateX(3em);
          transform: translateX(3em);
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

.cols-wrapper--hero > .cols > .col--text.scroll-active {
  opacity: 1;
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
}

.image-square {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
}

.image-square img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}

.section--bg-footer {
  padding-bottom: 31.25%;
  padding-bottom: calc(31.25% + 60px);
}

.section--bg-footer .section__bg {
  top: auto;
  height: 100%;
  max-height: 31.25vw;
  background-image: url("../img/mountains.jpg");
  background-position: center bottom;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.section--bg-footer .section__bg.scroll-activate {
  opacity: 0;
  -webkit-transform: none !important;
      -ms-transform: none !important;
          transform: none !important;
  -webkit-transition-delay: 0.1s;
       -o-transition-delay: 0.1s;
          transition-delay: 0.1s;
  -webkit-transition-duration: 0.7s;
       -o-transition-duration: 0.7s;
          transition-duration: 0.7s;
}

.section--bg-footer .section__bg.scroll-active {
  opacity: 1;
}

.section--home {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 230px 0 50px;
}

@media (max-height: 900px) {
  .section--home {
    padding-top: 150px;
  }
}

@media (max-height: 800px) {
  .section--home {
    padding: 100px 0 0;
  }
}

@media (max-height: 700px) {
  .section--home {
    padding-top: 50px;
  }
}

@media (max-height: 500px) {
  .section--home {
    padding-top: 0;
  }
}

.bg {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg.scroll-activate {
  opacity: 0;
  -webkit-transition-duration: 1.8s;
       -o-transition-duration: 1.8s;
          transition-duration: 1.8s;
}

.bg.scroll-active {
  opacity: 1;
}

.page-home .bg {
  background-image: url("../img/mountains-home.jpg");
  background-position: center top;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .page-home .bg {
    background-size: 1440px auto;
  }
}

@media (max-height: 800px) {
  .page-home .bg {
    background-position-y: -70px;
  }
}

@media (max-height: 700px) {
  .page-home .bg {
    background-position-y: -80px;
  }
}

@media (max-height: 600px) {
  .page-home .bg {
    background-position-y: -70px;
  }
}

@media (max-height: 700px) {
  .page-home .footer {
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

.logo--home.scroll-activate {
  opacity: 0;
  -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-transition-duration: 0.9s;
       -o-transition-duration: 0.9s;
          transition-duration: 0.9s;
}

.logo--home.scroll-active {
  opacity: 1;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

.site-slogan {
  font-size: 22px;
  line-height: 1.6;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin-top: 100px;
}

.site-slogan:first-child {
  margin-top: 0;
}

@media (max-height: 900px) {
  .site-slogan {
    margin-top: 85px;
    font-size: 20px;
    line-height: 2;
  }
}

@media (max-width: 767px) {
  .site-slogan {
    font-size: 20px;
    line-height: 2;
    letter-spacing: 4px;
    margin-top: 85px;
  }
}

@media (max-width: 767px) and (max-height: 580px) {
  .site-slogan {
    font-size: 16px;
  }
}

@media (max-height: 800px) {
  .site-slogan {
    margin-top: 55px;
  }
}

@media (max-height: 700px) {
  .site-slogan {
    margin-top: 40px;
  }
}

.site-slogan.scroll-activate {
  opacity: 0;
  -webkit-transform: translateY(-1.5em);
      -ms-transform: translateY(-1.5em);
          transform: translateY(-1.5em);
  -webkit-transition-delay: 0.3s;
       -o-transition-delay: 0.3s;
          transition-delay: 0.3s;
  -webkit-transition-duration: 0.9s;
       -o-transition-duration: 0.9s;
          transition-duration: 0.9s;
}

.site-slogan.scroll-active {
  opacity: 1;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

.section--contact {
  padding: 60px 0;
}

.section--text-justify p {
  text-align: justify;
}

.scroll-activate-default .scroll-activate {
  opacity: 0;
  -webkit-transform: translateY(-1.5em);
      -ms-transform: translateY(-1.5em);
          transform: translateY(-1.5em);
  -webkit-transition-duration: 0.7s;
       -o-transition-duration: 0.7s;
          transition-duration: 0.7s;
}

.scroll-activate-default .scroll-active {
  opacity: 1;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

[data-delay='50'] {
  -webkit-transition-delay: 50ms;
       -o-transition-delay: 50ms;
          transition-delay: 50ms;
}

[data-delay='100'] {
  -webkit-transition-delay: 100ms;
       -o-transition-delay: 100ms;
          transition-delay: 100ms;
}

[data-delay='150'] {
  -webkit-transition-delay: 150ms;
       -o-transition-delay: 150ms;
          transition-delay: 150ms;
}

[data-delay='200'] {
  -webkit-transition-delay: 200ms;
       -o-transition-delay: 200ms;
          transition-delay: 200ms;
}

[data-delay='250'] {
  -webkit-transition-delay: 250ms;
       -o-transition-delay: 250ms;
          transition-delay: 250ms;
}

[data-delay='300'] {
  -webkit-transition-delay: 300ms;
       -o-transition-delay: 300ms;
          transition-delay: 300ms;
}

[data-delay='350'] {
  -webkit-transition-delay: 350ms;
       -o-transition-delay: 350ms;
          transition-delay: 350ms;
}

[data-delay='400'] {
  -webkit-transition-delay: 400ms;
       -o-transition-delay: 400ms;
          transition-delay: 400ms;
}

[data-delay='450'] {
  -webkit-transition-delay: 450ms;
       -o-transition-delay: 450ms;
          transition-delay: 450ms;
}

[data-delay='500'] {
  -webkit-transition-delay: 500ms;
       -o-transition-delay: 500ms;
          transition-delay: 500ms;
}

[data-delay='550'] {
  -webkit-transition-delay: 550ms;
       -o-transition-delay: 550ms;
          transition-delay: 550ms;
}

[data-delay='600'] {
  -webkit-transition-delay: 600ms;
       -o-transition-delay: 600ms;
          transition-delay: 600ms;
}

[data-delay='650'] {
  -webkit-transition-delay: 650ms;
       -o-transition-delay: 650ms;
          transition-delay: 650ms;
}

[data-delay='700'] {
  -webkit-transition-delay: 700ms;
       -o-transition-delay: 700ms;
          transition-delay: 700ms;
}

[data-delay='750'] {
  -webkit-transition-delay: 750ms;
       -o-transition-delay: 750ms;
          transition-delay: 750ms;
}

[data-delay='800'] {
  -webkit-transition-delay: 800ms;
       -o-transition-delay: 800ms;
          transition-delay: 800ms;
}

[data-delay='850'] {
  -webkit-transition-delay: 850ms;
       -o-transition-delay: 850ms;
          transition-delay: 850ms;
}

[data-delay='900'] {
  -webkit-transition-delay: 900ms;
       -o-transition-delay: 900ms;
          transition-delay: 900ms;
}

[data-delay='950'] {
  -webkit-transition-delay: 950ms;
       -o-transition-delay: 950ms;
          transition-delay: 950ms;
}

[data-delay='1000'] {
  -webkit-transition-delay: 1000ms;
       -o-transition-delay: 1000ms;
          transition-delay: 1000ms;
}

[data-delay='1050'] {
  -webkit-transition-delay: 1050ms;
       -o-transition-delay: 1050ms;
          transition-delay: 1050ms;
}

[data-delay='1100'] {
  -webkit-transition-delay: 1100ms;
       -o-transition-delay: 1100ms;
          transition-delay: 1100ms;
}

[data-delay='1150'] {
  -webkit-transition-delay: 1150ms;
       -o-transition-delay: 1150ms;
          transition-delay: 1150ms;
}

[data-delay='1200'] {
  -webkit-transition-delay: 1200ms;
       -o-transition-delay: 1200ms;
          transition-delay: 1200ms;
}

[data-delay='1250'] {
  -webkit-transition-delay: 1250ms;
       -o-transition-delay: 1250ms;
          transition-delay: 1250ms;
}

[data-delay='1300'] {
  -webkit-transition-delay: 1300ms;
       -o-transition-delay: 1300ms;
          transition-delay: 1300ms;
}

[data-delay='1350'] {
  -webkit-transition-delay: 1350ms;
       -o-transition-delay: 1350ms;
          transition-delay: 1350ms;
}

[data-delay='1400'] {
  -webkit-transition-delay: 1400ms;
       -o-transition-delay: 1400ms;
          transition-delay: 1400ms;
}

[data-delay='1450'] {
  -webkit-transition-delay: 1450ms;
       -o-transition-delay: 1450ms;
          transition-delay: 1450ms;
}

[data-delay='1500'] {
  -webkit-transition-delay: 1500ms;
       -o-transition-delay: 1500ms;
          transition-delay: 1500ms;
}

[data-delay='1550'] {
  -webkit-transition-delay: 1550ms;
       -o-transition-delay: 1550ms;
          transition-delay: 1550ms;
}

[data-delay='1600'] {
  -webkit-transition-delay: 1600ms;
       -o-transition-delay: 1600ms;
          transition-delay: 1600ms;
}

[data-delay='1650'] {
  -webkit-transition-delay: 1650ms;
       -o-transition-delay: 1650ms;
          transition-delay: 1650ms;
}

[data-delay='1700'] {
  -webkit-transition-delay: 1700ms;
       -o-transition-delay: 1700ms;
          transition-delay: 1700ms;
}

[data-delay='1750'] {
  -webkit-transition-delay: 1750ms;
       -o-transition-delay: 1750ms;
          transition-delay: 1750ms;
}

[data-delay='1800'] {
  -webkit-transition-delay: 1800ms;
       -o-transition-delay: 1800ms;
          transition-delay: 1800ms;
}

[data-delay='1850'] {
  -webkit-transition-delay: 1850ms;
       -o-transition-delay: 1850ms;
          transition-delay: 1850ms;
}

[data-delay='1900'] {
  -webkit-transition-delay: 1900ms;
       -o-transition-delay: 1900ms;
          transition-delay: 1900ms;
}

[data-delay='1950'] {
  -webkit-transition-delay: 1950ms;
       -o-transition-delay: 1950ms;
          transition-delay: 1950ms;
}

[data-delay='2000'] {
  -webkit-transition-delay: 2000ms;
       -o-transition-delay: 2000ms;
          transition-delay: 2000ms;
}

[data-delay='2050'] {
  -webkit-transition-delay: 2050ms;
       -o-transition-delay: 2050ms;
          transition-delay: 2050ms;
}

[data-delay='2100'] {
  -webkit-transition-delay: 2100ms;
       -o-transition-delay: 2100ms;
          transition-delay: 2100ms;
}

[data-delay='2150'] {
  -webkit-transition-delay: 2150ms;
       -o-transition-delay: 2150ms;
          transition-delay: 2150ms;
}

[data-delay='2200'] {
  -webkit-transition-delay: 2200ms;
       -o-transition-delay: 2200ms;
          transition-delay: 2200ms;
}

[data-delay='2250'] {
  -webkit-transition-delay: 2250ms;
       -o-transition-delay: 2250ms;
          transition-delay: 2250ms;
}

[data-delay='2300'] {
  -webkit-transition-delay: 2300ms;
       -o-transition-delay: 2300ms;
          transition-delay: 2300ms;
}

[data-delay='2350'] {
  -webkit-transition-delay: 2350ms;
       -o-transition-delay: 2350ms;
          transition-delay: 2350ms;
}

[data-delay='2400'] {
  -webkit-transition-delay: 2400ms;
       -o-transition-delay: 2400ms;
          transition-delay: 2400ms;
}

[data-delay='2450'] {
  -webkit-transition-delay: 2450ms;
       -o-transition-delay: 2450ms;
          transition-delay: 2450ms;
}

[data-delay='2500'] {
  -webkit-transition-delay: 2500ms;
       -o-transition-delay: 2500ms;
          transition-delay: 2500ms;
}

/*

Common property classes

 */
.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.overflow-hidden {
  overflow: hidden;
}

.reset-margin {
  margin: 0;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.hidden-absolute {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

@media (max-width: 767px) {
  .text-align-left-on-mobile {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .text-align-right-on-mobile {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .text-align-center-on-mobile {
    text-align: center;
  }
}

.vmiddle {
  vertical-align: middle;
}

.vbottom {
  vertical-align: bottom;
}

.vbaseline {
  vertical-align: baseline;
}

.clear {
  clear: both;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.nowrap {
  white-space: nowrap;
}

.no-text-transform {
  text-transform: none;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.nounderline {
  text-decoration: none;
}

.underline-hover:hover {
  text-decoration: underline;
}

.underline-hover:active {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.pointer-events-none {
  pointer-events: none;
}

.color-white {
  color: #fff;
}

.color-inherit-important {
  color: inherit !important;
}

.color-default {
  color: #646569;
}

.color-default-muted {
  color: #777;
}

.color-red {
  color: #ff5d5d;
}

.color-active {
  color: #484848;
}

.color-active2 {
  color: #484848;
}

.font-0 {
  font-size: 0;
}

.font-1rem {
  font-size: 1rem;
}

.font-small {
  font-size: 12px;
}

.font-smaller {
  font-size: 14px;
}

.font-normal {
  font-size: 16px;
}

.font-larger {
  font-size: 18px;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.ml10 {
  margin-left: 10px;
}

.mt0 {
  margin-top: 0;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.minw280 {
  min-width: 280px;
}

.mt40 {
  margin-top: 40px;
}

.minw100percent {
  min-width: 100%;
}

.mw480 {
  max-width: 480px;
}

.mw600 {
  max-width: 600px;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

/*# sourceMappingURL=style.css.map */
